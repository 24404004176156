import { call, put, takeLatest } from 'redux-saga/effects';

import { container } from '../di';

import {
  fetchAverageTimeSpentInAppAsync,
  fetchHeatMapAsync,
  fetchInfluencersTotalsAsync,
  fetchNewInfluencersPast30DaysAsync,
  fetchNewInfluencersPast12MonthsAsync,
  fetchDemographicsAsync,
} from './actions';

const analyticsService = container.get('AnalyticsService');

function* fetchAverageTimeSpentInApp() {
  try {
    const response = yield call(analyticsService.getAverageTimeSpentInApp);

    yield put(fetchAverageTimeSpentInAppAsync.success(response));
  } catch (err) {
    yield put(fetchAverageTimeSpentInAppAsync.failure(err));
  }
}

function* fetchDemographics({ payload }) {
  try {
    const response = yield call(analyticsService.getDemographics, payload);

    yield put(fetchDemographicsAsync.success(response));
  } catch (err) {
    yield put(fetchDemographicsAsync.failure(err));
  }
}

function* fetchHeatMap({ payload }) {
  try {
    const response = yield call(analyticsService.getHeatMap, payload);

    yield put(fetchHeatMapAsync.success(response));
  } catch (err) {
    yield put(fetchHeatMapAsync.failure(err));
  }
}

function* fetchInfluencersTotals() {
  try {
    const response = yield call(analyticsService.getInfluencerTotals);

    yield put(fetchInfluencersTotalsAsync.success(response));
  } catch (err) {
    yield put(fetchInfluencersTotalsAsync.failure(err));
  }
}

function* fetchNewInfluencersPast30Days() {
  try {
    const response = yield call(analyticsService.getNewInfluencersPast30Days);

    yield put(fetchNewInfluencersPast30DaysAsync.success(response));
  } catch (err) {
    yield put(fetchNewInfluencersPast30DaysAsync.failure(err));
  }
}

function* fetchNewInfluencersPast12Months() {
  try {
    const response = yield call(analyticsService.getNewInfluencersPast12Months);

    yield put(fetchNewInfluencersPast12MonthsAsync.success(response));
  } catch (err) {
    yield put(fetchNewInfluencersPast12MonthsAsync.failure(err));
  }
}

export function* analyticsActionWatcher() {
  yield takeLatest(
    fetchAverageTimeSpentInAppAsync.request.type,
    fetchAverageTimeSpentInApp
  );

  yield takeLatest(fetchDemographicsAsync.request.type, fetchDemographics);

  yield takeLatest(fetchHeatMapAsync.request.type, fetchHeatMap);

  yield takeLatest(
    fetchInfluencersTotalsAsync.request.type,
    fetchInfluencersTotals
  );

  yield takeLatest(
    fetchNewInfluencersPast30DaysAsync.request.type,
    fetchNewInfluencersPast30Days
  );

  yield takeLatest(
    fetchNewInfluencersPast12MonthsAsync.request.type,
    fetchNewInfluencersPast12Months
  );
}
