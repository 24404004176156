import axios from 'axios';
import qs from 'querystring';

export class DataService {
  constructor(tokenService) {
    this.tokenService = tokenService;
  }

  fetch = async ({
    authorization = true,
    body,
    bodyType,
    headers = {},
    method = 'POST',
    params,
    url,
  }) => {
    let request = {
      url,
      method,
      headers,
      params,
    };

    if (authorization) {
      const { accessToken } = this.tokenService.retrieveTokens();

      headers['Authorization'] = `Bearer ${accessToken}`;
    }

    switch (bodyType) {
      case 'urlencoded':
        request.data = qs.stringify(body);
        request.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        break;
      case 'json':
      default:
        request.data = body;
        break;
    }

    try {
      const response = await axios(request);

      return response;
    } catch (err) {
      // Hack because the API won't return 401 in some cases
      // FIXME: when the API is fixed
      if (err.message === 'Network Error') {
        const customError = new Error('Unauthorized');

        customError.response = { status: 401 };

        throw customError;
      } else {
        throw err;
      }
    }
  };
}
