import { createSelector } from 'reselect';

const analyticsStateSelector = (state) => state.analytics;

export const averageTimeSpentInAppSelector = createSelector(
  analyticsStateSelector,
  (state) => state.influencers.averageTimeSpentInApp
);

export const demographicsSelector = createSelector(
  analyticsStateSelector,
  (state) => state.demographics
);

export const fetchingSelector = createSelector(
  analyticsStateSelector,
  (state) => state.fetching
);

export const heatMapSelector = createSelector(
  analyticsStateSelector,
  (state) => state.heatMap
);

export const influencersTotalsSelector = createSelector(
  analyticsStateSelector,
  (state) => state.influencers.totals
);

export const newInfluencersPast30DaysSelector = createSelector(
  analyticsStateSelector,
  (state) => state.influencers.newPast30Days
);

export const newInfluencersPast12MonthsSelector = createSelector(
  analyticsStateSelector,
  (state) => state.influencers.newPast12Months
);
