import React, { useEffect } from 'react';

import { Typography } from 'antd';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Tabs } from 'eight.js.ui-common';
import querystring from 'query-string';
import _ from 'lodash';

import InfluencersReporting from './components/InfluencersReporting/InfluencersReporting';
import DemographicsReporting from './components/DemographicsReporting/DemographicsReporting';

import { fetchTimezonesAsync } from '../../store/admin';
import {
  fetchDemographicsAsync,
  fetchHeatMapAsync,
  fetchInfluencersTotalsAsync,
  fetchNewInfluencersPast30DaysAsync,
  fetchNewInfluencersPast12MonthsAsync,
} from '../../store/analytics';

import { getDefaultSearch } from './util';

import style from './Reporting.module.scss';
import { useMount } from 'react-use';

const Reporting = ({
  fetchDemographics,
  fetchHeatMap,
  fetchInfluencersTotals,
  fetchNewInfluencersPast30Days,
  fetchNewInfluencersPast12Months,
  // fetchTimezones,
  push,
}) => {
  const { pathname, search } = useLocation();
  const parentMatch = useRouteMatch();

  useMount(() => {
    // fetchTimezones();
  });

  useEffect(() => {
    if (!search) {
      const defaultSearch = getDefaultSearch(pathname);

      push(`${pathname}?${querystring.stringify(defaultSearch)}`);
    }
  }, [pathname, push, search]);

  useEffect(() => {
    if (search) {
      const filters = _.pickBy(
        querystring.parse(search),
        (val) => val !== 'all'
      );

      if (pathname.includes('demographics')) {
        fetchDemographics(filters);
        fetchHeatMap(filters);
      }

      if (pathname.includes('users')) {
        fetchInfluencersTotals();
        fetchNewInfluencersPast12Months(filters);
        fetchNewInfluencersPast30Days(filters);
      }
    }
  }, [
    fetchDemographics,
    fetchHeatMap,
    fetchInfluencersTotals,
    fetchNewInfluencersPast12Months,
    fetchNewInfluencersPast30Days,
    pathname,
    search,
  ]);

  return (
    <>
      <Typography.Title className={style.title} level={2}>
        Reporting
      </Typography.Title>

      <Switch>
        <Route
          path={`${parentMatch.path}/:type`}
          render={({ match }) => {
            return (
              <Tabs
                defaultActiveKey={match.params.type}
                onChange={(key) => push(`${parentMatch.path}/${key}`)}
                type="card"
              >
                <Tabs.TabPane tab="Users" key="users">
                  <InfluencersReporting />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Demographics" key="demographics">
                  <DemographicsReporting />
                </Tabs.TabPane>
              </Tabs>
            );
          }}
        />
        <Route
          path={`${parentMatch.path}`}
          render={() => <Redirect to={`${parentMatch.path}/users`} />}
        />
      </Switch>
    </>
  );
};

const mapStateToProps = (state) => ({});
const dispatchProps = {
  fetchDemographics: fetchDemographicsAsync.request,
  fetchHeatMap: fetchHeatMapAsync.request,
  fetchInfluencersTotals: fetchInfluencersTotalsAsync.request,
  fetchNewInfluencersPast12Months: fetchNewInfluencersPast12MonthsAsync.request,
  fetchNewInfluencersPast30Days: fetchNewInfluencersPast30DaysAsync.request,
  fetchTimezones: fetchTimezonesAsync.request,
  push,
};

export default connect(mapStateToProps, dispatchProps)(Reporting);
