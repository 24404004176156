import {
  all,
  call,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

import { adminActionWatcher } from './admin';
import { analyticsActionWatcher } from './analytics';
import {
  authActionWatcher,
  logout,
  refreshAsync,
  refreshingSelector,
} from './auth';

// Try to refresh token or else redirect to login if we ever get a 401
function* fourOhOneActionWatcher() {
  yield takeEvery(
    (action) =>
      action.type !== refreshAsync.request.type &&
      action.type.includes('REQUEST'),
    function* (action) {
      const { failure } = yield race({
        success: take(action.type.replace('REQUEST', 'SUCCESS')),
        failure: take(action.type.replace('REQUEST', 'FAILURE')),
      });

      if (failure?.payload?.response?.status === 401) {
        const refreshing = yield select(refreshingSelector);

        if (!refreshing) {
          yield put(refreshAsync.request());
        }

        const { success } = yield race({
          success: take(refreshAsync.success.type),
          failure: take(refreshAsync.failure.type),
        });

        if (success) {
          yield put(action);
        } else {
          // TODO: Figure out how to not have logout called multiple times here
          yield put(logout());
        }
      }
    }
  );
}

function* fiveHundredActionWatcher() {
  yield takeEvery(
    (action) => action.payload?.response?.status >= 500,
    function* ({ payload }) {
      yield call(Sentry.captureException, payload);
    }
  );
}

export function* rootSaga() {
  yield all([
    adminActionWatcher(),
    analyticsActionWatcher(),
    authActionWatcher(),
    fiveHundredActionWatcher(),
    fourOhOneActionWatcher(),
  ]);
}
