export default {
  analyticsAPI: process.env.REACT_APP_ANALYTICS_API,
  authAPI: process.env.REACT_APP_AUTH_API,
  communityTilesAPI: process.env.REACT_APP_COMMUNITY_TILES_API,
  usersAPI: process.env.REACT_APP_USERS_API,
  adminAPI: process.env.REACT_APP_ADMIN_API,
  auth: {
    accessTokenStorageKey: 'phWc2MTSnhLnVXL',
    refreshTokenStorageKey: 'fM5VF8AOq6ZDmUL',
    clientSecret: process.env.REACT_APP_CLIENT_SECRET,
  },
  app: {
    colors: {
      primaryColor: '#2a2153', // primary color for all components
      normalColor: '#d9d9d9', // normal color
      linkColor: '#1890ff', // link color
      infoColor: '#2a2153', // info state color
      successColor: '#63a48c', // success state color
      warningColor: '#faad14', // warning state color
      errorColor: '#d06a6f', // error state color
      headingColor: 'rgba(0, 0, 0, 0.85)', // heading text color
      textColor: 'rgba(0, 0, 0, 0.65)', // major text color
      textColorSecondary: 'rgba(0, 0, 0, 0.45)', // secondary text color
      disabledColor: 'rgba(0, 0, 0, 0.25)', // disable state color
      borderColorBase: '#d9d9d9', // major border color
    },
  },
};
