import React from 'react';

import { Switch, Route } from 'react-router';
import { ProtectedRoute } from 'eight.js.ui-common';

import Layout from './components/Layout/Layout';
import Login from './pages/login/Login';

import { container } from './store/di';

import './App.scss';

const tokenService = container.get('TokenService');

const App = () => {
  const isAuthenticated = async () => {
    const { accessToken, refreshToken } = tokenService.retrieveTokens();

    if (!accessToken && !refreshToken) {
      return false;
    }

    const expired = tokenService.isExpired(accessToken);

    return Boolean(accessToken) && Boolean(refreshToken) && !expired;
  };

  return (
    <div>
      <Switch>
        <Route path="/login" exact component={Login} />
        <ProtectedRoute
          canActivate={isAuthenticated}
          path="/"
          redirectPath="/login"
        >
          <Layout />
        </ProtectedRoute>
      </Switch>
    </div>
  );
};

export default App;
