import _ from 'lodash';

import { createReducer } from 'eight.js.ui-common';

import { toggleSidebar } from './actions';

const initialState = {
  sidebarOpen: true,
};

export const uiReducer = createReducer('@@ui', initialState, {
  [toggleSidebar]: ({ state, action }) => {
    state.sidebarOpen = _.isNil(action.payload)
      ? !state.sidebarOpen
      : action.payload;
  },
});
