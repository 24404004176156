import React from 'react';

import { Empty } from 'antd';
import { connect } from 'react-redux';
import { Card } from 'eight.js.ui-common';

import Spinner from '../../../../components/Spinner/Spinner';

import BarChart from '../BarChart/BarChart';
import GeographicList from '../GeographicList/GeographicList';
import MapChart from '../MapChart/MapChart';
import PieChart from '../PieChart/PieChart';

import DemographicsFilters from '../DemographicsFilters/DemographicsFilters';

import {
  demographicsSelector,
  heatMapSelector,
} from '../../../../store/analytics';

import {
  getAgeGroupBarChartOptions,
  getCardBodyStyle,
  getMapChartOptions,
  getPieChartOptions,
} from './util';

import style from './DemographicsReporting.module.scss';

const DemographicsReporting = ({ demographics, heatMap }) => {
  const {
    fetching: demographicsFetching,
    byCountry,
    byCity,
    byGender,
    byAgeGroup,
    byPlatform,
  } = demographics;

  const fetching = demographicsFetching || heatMap.fetching;

  const showAgeGroup = !byAgeGroup.data.every(({ x }) => x === 0);
  const showCity = Boolean(byCity.data.length);
  const showCountry = Boolean(byCountry.data.length);
  const showGender = Boolean(byGender.data.length);
  const showPlatform = Boolean(byPlatform.data.length);
  const showHeatMap = Boolean(heatMap.byCountry.data.length);

  const getChartContainer = (show, chart, title) => {
    if (fetching) {
      return <Spinner />;
    }

    if (show) {
      return chart;
    } else {
      return <Empty description={`No${title ? ` ${title}` : ''} data`} />;
    }
  };

  return (
    <>
      <DemographicsFilters />

      <div className={style.chartsContainer}>
        <Card
          bodyStyle={getCardBodyStyle(!fetching && showHeatMap)}
          className={style.chartContainer}
        >
          {getChartContainer(
            showHeatMap,
            <MapChart options={getMapChartOptions(heatMap.byCountry)} />,
            'country'
          )}
        </Card>
      </div>

      <div className={style.chartsContainer}>
        <Card
          bodyStyle={getCardBodyStyle(!fetching && showCountry)}
          className={style.chartContainer}
        >
          {getChartContainer(
            showCountry,
            <GeographicList dataSource={byCountry} fetching={fetching} />,
            'country'
          )}
        </Card>
        <Card
          bodyStyle={getCardBodyStyle(!fetching && showCity)}
          className={style.chartContainer}
        >
          {getChartContainer(
            showCity,
            <GeographicList dataSource={byCity} fetching={fetching} />,
            'city'
          )}
        </Card>
      </div>

      <div className={style.chartsContainer}>
        <Card
          bodyStyle={getCardBodyStyle(!fetching && showGender)}
          className={style.chartContainer}
        >
          {getChartContainer(
            showGender,
            <PieChart options={getPieChartOptions(byGender)} />,
            'gender'
          )}
        </Card>

        <Card
          bodyStyle={getCardBodyStyle(!fetching && showPlatform)}
          className={style.chartContainer}
        >
          {getChartContainer(
            showPlatform,
            <PieChart options={getPieChartOptions(byPlatform)} />,
            'platform'
          )}
        </Card>
      </div>

      <div className={style.chartsContainer}>
        <Card
          bodyStyle={getCardBodyStyle(!fetching && showAgeGroup)}
          className={style.chartContainer}
        >
          {getChartContainer(
            showAgeGroup,
            <BarChart options={getAgeGroupBarChartOptions(byAgeGroup)} />,
            'age group'
          )}
        </Card>
        <div className={style.chartContainer}></div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  demographics: demographicsSelector(state),
  heatMap: heatMapSelector(state),
});
const dispatchProps = {};

export default connect(mapStateToProps, dispatchProps)(DemographicsReporting);
