import config from '../../config';

export class AdminService {
  constructor(dataService) {
    this.dataService = dataService;
  }

  getCities = async ({ city, country, region }) => {
    const response = await this.dataService.fetch({
      url: `${config.adminAPI}/locations/cities`,
      method: 'GET',
      params: {
        countryCode: country,
        regionCode: region,
        cityName: city,
      },
    });

    return response.data;
  };

  getCountries = async ({ country }) => {
    const response = await this.dataService.fetch({
      url: `${config.adminAPI}/locations/countries/${country}`,
      method: 'GET',
    });

    return response.data;
  };

  getRegions = async ({ country, region }) => {
    const response = await this.dataService.fetch({
      url: `${config.adminAPI}/locations/countries/${country}/regions/${region}`,
      method: 'GET',
    });

    return response.data;
  };

  getTimezones = async () => {
    const response = await this.dataService.fetch({
      url: `${config.adminAPI}/timezones`,
      method: 'GET',
    });

    return response.data;
  };
}
