import React from 'react';

import _ from 'lodash';
import moment from 'moment';
import classnames from 'classnames';
import { Empty } from 'antd';
import { connect } from 'react-redux';
import { Card, Countup } from 'eight.js.ui-common';

import Spinner from '../../../../components/Spinner/Spinner';

import ColumnChart from '../ColumnChart/ColumnChart';

import {
  influencersTotalsSelector,
  newInfluencersPast30DaysSelector,
  newInfluencersPast12MonthsSelector,
} from '../../../../store/analytics';

import {
  getColumnChartOptions,
  getPeriodString,
  getTodayString,
  getCardBodyStyle,
} from './util';

import style from './InfluencersReporting.module.scss';

const NumberDisplay = ({ fetching, number }) => {
  const spinning = fetching || _.isNil(number);

  return (
    <div
      className={classnames(style.cover, { [style.coverFetching]: spinning })}
    >
      {spinning ? <Spinner /> : <Countup number={number}></Countup>}
    </div>
  );
};

const InfluencersReporting = ({
  newInfluencersPast12Months,
  newInfluencersPast30Days,
  totals,
}) => {
  const { total, totalQuarter, totalMonth, totalWeek, totalDay } = totals;

  const fetching =
    newInfluencersPast12Months.fetching ||
    newInfluencersPast30Days.fetching ||
    totals.fetching;

  const monthString = getPeriodString('month');
  const quarterString = getPeriodString('quarter');
  const weekString = getPeriodString('week');
  const todayString = getTodayString();

  const showNewPast12Months = Boolean(
    newInfluencersPast12Months.series[0]?.data?.length
  );
  const showNewPast30Days = Boolean(
    newInfluencersPast30Days.series[0]?.data?.length
  );

  const getChartContainer = (show, chart) => {
    if (fetching) {
      return <Spinner />;
    }

    if (show) {
      return chart;
    } else {
      return <Empty description="No data" />;
    }
  };

  return (
    <>
      <div className={style.cardContainer}>
        <Card className={style.totalContainer}>
          <Card.Meta
            title="Total Users"
            description={true}
            style={{ marginBottom: 21 }}
          ></Card.Meta>
          <NumberDisplay fetching={fetching} number={total} />
        </Card>
        <Card className={style.totalContainer}>
          <Card.Meta
            title={moment().format('[Q]Q')}
            description={quarterString}
          ></Card.Meta>
          <NumberDisplay fetching={fetching} number={totalQuarter} />
        </Card>
        <Card className={style.totalContainer}>
          <Card.Meta title="This Month" description={monthString}></Card.Meta>
          <NumberDisplay fetching={fetching} number={totalMonth} />
        </Card>
        <Card className={style.totalContainer}>
          <Card.Meta title="This Week" description={weekString}></Card.Meta>
          <NumberDisplay fetching={fetching} number={totalWeek} />
        </Card>
        <Card className={style.totalContainer}>
          <Card.Meta title="Today" description={todayString}></Card.Meta>
          <NumberDisplay fetching={fetching} number={totalDay} />
        </Card>
      </div>

      <div className={style.chartsContainer}>
        <Card
          bodyStyle={getCardBodyStyle(!fetching && showNewPast30Days)}
          className={style.chartContainer}
        >
          {getChartContainer(
            showNewPast30Days,
            <ColumnChart
              options={getColumnChartOptions({
                ...newInfluencersPast30Days,
                titleText: 'New Users Daily',
              })}
            />
          )}
        </Card>
      </div>

      <div className={style.chartsContainer}>
        <Card
          bodyStyle={getCardBodyStyle(!fetching && showNewPast12Months)}
          className={style.chartContainer}
        >
          {getChartContainer(
            showNewPast12Months,
            <ColumnChart
              options={getColumnChartOptions({
                ...newInfluencersPast12Months,
                titleText: 'New Users Monthly',
              })}
            />
          )}
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  newInfluencersPast12Months: newInfluencersPast12MonthsSelector(state),
  newInfluencersPast30Days: newInfluencersPast30DaysSelector(state),
  totals: influencersTotalsSelector(state),
});
const dispatchProps = {};

export default connect(mapStateToProps, dispatchProps)(InfluencersReporting);
