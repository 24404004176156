import { DIContainer } from 'eight.js.ui-common';

import { AdminService } from './admin/service';
import { AnalyticsService } from './analytics/service';
import { AuthService } from './auth/service';
import { DataService } from './data-service';
import { TokenService } from './token-service';

export const container = new DIContainer();

container.singleton('AdminService', AdminService, ['DataService']);
container.singleton('AnalyticsService', AnalyticsService, ['DataService']);
container.singleton('AuthService', AuthService, [
  'DataService',
  'TokenService',
]);
container.singleton('DataService', DataService, ['TokenService']);
container.singleton('TokenService', TokenService);
