import moment from 'moment';

import { numberWithCommas } from '../../util';

export const getScaleDetails = (min, max) => {
  const range = max - min;

  const stepCount = 10;
  const roughStep = range / (stepCount - 1);

  const goodNormalizedSteps = [1, 2, 5, 10];

  const stepPower = Math.pow(10, -Math.floor(Math.log10(Math.abs(roughStep))));
  const normalizedStep = roughStep * stepPower;
  const goodNormalizedStep = goodNormalizedSteps.find(
    (n) => n >= normalizedStep
  );

  const step = Math.round(goodNormalizedStep / stepPower);
  const scaleMin = Math.round(Math.floor(min / step) * step);
  const scaleMax = Math.round(Math.ceil(max / step) * step);

  return {
    scaleMin,
    scaleMax,
    step,
  };
};

export const getColumnChartOptions = ({ xAxis, series, titleText }) => {
  let tickInterval = 1;
  let max = null;

  if (series[0]?.data) {
    const sorted = series[0].data.map(([_, val]) => val).sort((a, b) => a - b);
    const { scaleMax, step } = getScaleDetails(
      sorted[0],
      sorted[sorted.length - 1]
    );

    if (!isNaN(step)) {
      tickInterval = step;
      max = scaleMax;
    }
  }

  const categories = series[0]?.data?.map(([name]) => name);

  return {
    xAxis: {
      ...xAxis,
      categories,
    },
    yAxis: {
      max,
      tickInterval,
      title: {
        text: 'Users',
      },
    },
    title: {
      text: titleText,
      style: {
        fontSize: 14,
      },
    },
    tooltip: {
      borderColor: '#EEEEEE',
      borderRadius: 20,
      formatter() {
        return `
        <div>
          <b>${this.point.name}</b>
          <br/>
          <span>${numberWithCommas(this.point.y)}</span>
        <div>
      `;
      },
    },
    series,
  };
};

export const getLineChartOptions = ({ xAxis, series, titleText }) => {
  const getTimeString = () => {};

  return {
    xAxis: {
      ...xAxis,
      tickInterval: 2 * 24 * 60 * 60 * 1000,
      labels: {
        formatter() {
          return moment(this.value).format('MM/DD');
        },
      },
    },
    yAxis: {
      tickInterval: 30 * 60000,
      labels: {
        formatter() {
          return getTimeString(this.value);
        },
      },
      title: {
        text: 'Average Time Spent',
      },
    },
    title: {
      text: titleText,
      style: {
        fontSize: 14,
      },
    },
    tooltip: {
      borderColor: '#EEEEEE',
      borderRadius: 20,
      formatter() {
        return `
          <span>${this.point.name}</span>
          <br/>
          <span style="color:${this.point.color}">●</span> ${
          this.series.name
        }: <b>${getTimeString(this.point.y)}</b><br/>
        `;
      },
    },
    series,
  };
};

const dateFormat = 'MMM D, YYYY';

export const getTodayString = () => `${moment().format(dateFormat)}`;

export const getPeriodString = (periodName) => {
  return `${moment().startOf(periodName).format(dateFormat)} - ${moment()
    .endOf(periodName)
    .format(dateFormat)}`;
};

export const getCardBodyStyle = (show) => {
  let style = {
    height: '100%',
    width: '100%',
  };

  if (!show) {
    style.display = 'flex';
    style.alignItems = 'center';
    style.justifyContent = 'center';
  }

  return style;
};
