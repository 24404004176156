import React from 'react';

import Highcharts from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';

import geoJson from '@highcharts/map-collection/custom/world.geo.json';

const MapChart = ({ options }) => {
  const opts = {
    ...options,
    chart: {
      type: 'map',
      map: geoJson,
      height: '40%',
    },
    mapNavigation: {
      enabled: false,
    },
    dragDrop: {
      draggableX: true,
      draggableY: true,
    },
    colorAxis: {
      minColor: '#EEEEFF',
      maxColor: '#2a2153',
    },
    yAxis: {
      visible: false,
    },
    xAxis: {
      visible: false,
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
    },
    style: {
      height: 1000,
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={opts} />;
};

export default MapChart;

// format numbers ,
