import { createAction, createAsyncAction } from 'eight.js.ui-common';

export const clearCities = createAction('@@admin/CLEAR_CITIES');

export const clearCountries = createAction('@@admin/CLEAR_COUNTRIES');

export const clearRegions = createAction('@@admin/CLEAR_REGIONS');

export const fetchCitiesAsync = createAsyncAction('@@admin/FETCH_CITIES');

export const fetchCountriesAsync = createAsyncAction('@@admin/FETCH_COUNTRIES');

export const fetchRegionsAsync = createAsyncAction('@@admin/FETCH_REGIONS');

export const fetchTimezonesAsync = createAsyncAction('@@admin/FETCH_TIMEZONES');
