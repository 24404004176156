import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const BarChart = ({ options }) => {
  const opts = {
    ...options,
    colors: ['#63a48c', '#2a2153', '#f7a7ab', '#e9d563', '#e5e5e5'],
    chart: {
      type: 'bar',
    },
    legend: false,
  };

  return <HighchartsReact highcharts={Highcharts} options={opts} />;
};

export default BarChart;
