import _ from 'lodash';
import { createReducer, initialAsyncState } from 'eight.js.ui-common';

import {
  clearCities,
  clearCountries,
  clearRegions,
  fetchCitiesAsync,
  fetchCountriesAsync,
  fetchRegionsAsync,
  fetchTimezonesAsync,
} from './actions';

const initialState = {
  ...initialAsyncState,
  cities: {
    fetching: false,
    results: [],
  },
  countries: {
    fetching: false,
    results: [],
  },
  regions: {
    fetching: false,
    results: [],
  },
  timezones: {
    fetching: false,
    results: [],
  },
};

export const adminReducer = createReducer('@@admin', initialState, {
  [clearCities]: ({ state }) => {
    state.cities.results = [];
  },

  [clearCountries]: ({ state }) => {
    state.countries.results = [];
  },

  [clearRegions]: ({ state }) => {
    state.regions.results = [];
  },

  [fetchCitiesAsync.request]: ({ state }) => {
    state.cities.fetching = true;
  },

  [fetchCitiesAsync.success]: ({ state, action }) => {
    state.cities = {
      fetching: false,
      results: action.payload.map(({ id, name }) => ({
        display: name,
        value: name,
      })),
    };
  },

  [fetchCitiesAsync.failure]: ({ state }) => {
    state.cities.fetching = false;
  },

  [fetchCountriesAsync.request]: ({ state }) => {
    state.countries.fetching = true;
  },

  [fetchCountriesAsync.success]: ({ state, action }) => {
    state.countries = {
      fetching: false,
      results: action.payload.map(({ id, name }) => ({
        display: name,
        value: id,
      })),
    };
  },

  [fetchCountriesAsync.failure]: ({ state }) => {
    state.countries.fetching = false;
  },

  [fetchRegionsAsync.request]: ({ state }) => {
    state.regions.fetching = true;
  },

  [fetchRegionsAsync.success]: ({ state, action }) => {
    state.regions = {
      fetching: false,
      results: action.payload.map(({ isoCode, name }) => ({
        display: name,
        value: isoCode,
      })),
    };
  },

  [fetchRegionsAsync.failure]: ({ state }) => {
    state.regions.fetching = false;
  },

  [fetchTimezonesAsync.success]: ({ state, action }) => {
    // TODO: Make this readable
    state.timezones = _(action.payload)
      .map(({ name, rawUtcOffsetHours }) => {
        const positive = rawUtcOffsetHours >= 0;
        const offsetString = `UTC${positive ? '+' : '-'}${Math.abs(
          rawUtcOffsetHours
        )
          .toString()
          .padStart(2, '0')}:00`;

        return {
          display: name,
          value: `${name} ${offsetString}`,
          offsetString: offsetString,
          offsetNumber: rawUtcOffsetHours,
        };
      })
      .groupBy('offsetString')
      .values()
      .sort((groupA, groupB) => groupA[0].offsetNumber - groupB[0].offsetNumber)
      .map((group) => {
        const unique = _.uniqWith(group, _.isEqual);

        if (unique.length > 1) {
          return unique.filter(({ display }) => !display.startsWith('GMT'));
        }

        return unique;
      })
      .value();
  },
});
