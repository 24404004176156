import jwt from 'jsonwebtoken';
import SimpleCrypto from 'simple-crypto-js';

import config from '../config';

export class TokenService {
  crypto = new SimpleCrypto('fqy$RY0g');

  decodeToken = (token) => {
    return jwt.decode(token);
  };

  decryptToken = (token, mode = 'str') => {
    try {
      if (token) {
        return this.crypto.decrypt(token, mode === 'obj' ? true : false);
      }
    } catch (err) {
      return undefined;
    }
  };

  encryptToken = (token) => {
    try {
      return this.crypto.encrypt(token);
    } catch (err) {
      return undefined;
    }
  };

  isExpired = (token) => {
    const { exp } = this.decodeToken(token);

    return new Date().getTime() / 1000 >= exp;
  };

  retrieveTokens = () => {
    const accessToken = this.decryptToken(
      localStorage.getItem(config.auth.accessTokenStorageKey)
    );
    const refreshToken = this.decryptToken(
      localStorage.getItem(config.auth.refreshTokenStorageKey)
    );

    return { accessToken, refreshToken };
  };

  storeTokens = ({ accessToken, refreshToken }) => {
    localStorage.setItem(
      config.auth.accessTokenStorageKey,
      this.encryptToken(accessToken)
    );
    localStorage.setItem(
      config.auth.refreshTokenStorageKey,
      this.encryptToken(refreshToken)
    );
  };
}
