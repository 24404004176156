import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const PieChart = ({ options }) => {
  const opts = {
    ...options,
    colors: ['#2a2153', '#63a48c', '#f7a7ab', '#e9d563', '#e5e5e5'],
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        showInLegend: true,
        innerSize: '60%',
      },
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={opts} />;
};

export default PieChart;
