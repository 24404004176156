import { createAsyncAction } from 'eight.js.ui-common';

export const fetchAverageTimeSpentInAppAsync = createAsyncAction(
  '@@analytics/FETCH_AVERAGE_TIME_SPENT_IN_APP'
);

export const fetchDemographicsAsync = createAsyncAction(
  '@@analytics/FETCH_DEMOGRAPHICS'
);

export const fetchHeatMapAsync = createAsyncAction(
  '@@analytics/FETCH_HEAT_MAP'
);

export const fetchInfluencersTotalsAsync = createAsyncAction(
  '@@analytics/FETCH_INFLUENCERS_TOTALS'
);

export const fetchNewInfluencersPast30DaysAsync = createAsyncAction(
  '@@analytics/FETCH_NEW_INFLUENCERS_PAST_30_Days'
);

export const fetchNewInfluencersPast12MonthsAsync = createAsyncAction(
  '@@analytics/FETCH_NEW_INFLUENCERS_PAST_12_MONTHS'
);
