import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { container } from '../di';

import { loginAsync, logout as logoutAction, refreshAsync } from './actions';

const authService = container.get('AuthService');

function* login({ payload }) {
  try {
    const response = yield call(authService.login, payload);

    yield put(loginAsync.success(response));
    yield put(push('/home'));
  } catch (err) {
    yield put(loginAsync.failure(err));
  }
}

function* logout() {
  yield put(push('/login'));
}

function* refresh({ payload }) {
  try {
    const response = yield call(authService.refresh, payload);

    yield put(refreshAsync.success(response));
  } catch (err) {
    yield put(refreshAsync.failure(err));
  }
}

export function* authActionWatcher() {
  yield takeLatest(loginAsync.request.type, login);
  yield takeLatest(logoutAction.type, logout);
  yield takeLatest(refreshAsync.request.type, refresh);
}
