import { call, put, takeLatest } from 'redux-saga/effects';

import { container } from '../di';

import {
  fetchCitiesAsync,
  fetchCountriesAsync,
  fetchRegionsAsync,
  fetchTimezonesAsync,
} from './actions';

const adminService = container.get('AdminService');

function* fetchCities({ payload }) {
  try {
    const response = yield call(adminService.getCities, payload);

    yield put(fetchCitiesAsync.success(response));
  } catch (err) {
    yield put(fetchCitiesAsync.failure(err));
  }
}

function* fetchCountries({ payload }) {
  try {
    const response = yield call(adminService.getCountries, payload);

    yield put(fetchCountriesAsync.success(response));
  } catch (err) {
    yield put(fetchCountriesAsync.failure(err));
  }
}

function* fetchRegions({ payload }) {
  try {
    const response = yield call(adminService.getRegions, payload);

    yield put(fetchRegionsAsync.success(response));
  } catch (err) {
    yield put(fetchRegionsAsync.failure(err));
  }
}

function* fetchTimezones() {
  try {
    const response = yield call(adminService.getTimezones);

    yield put(fetchTimezonesAsync.success(response));
  } catch (err) {
    yield put(fetchTimezonesAsync.failure(err));
  }
}

export function* adminActionWatcher() {
  yield takeLatest(fetchCitiesAsync.request.type, fetchCities);

  yield takeLatest(fetchCountriesAsync.request.type, fetchCountries);

  yield takeLatest(fetchRegionsAsync.request.type, fetchRegions);

  yield takeLatest(fetchTimezonesAsync.request.type, fetchTimezones);
}
