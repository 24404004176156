import React from 'react';

import { Route, Switch, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { Dropdown, Icon, Layout as LayoutComponent, Menu } from 'antd';

import Home from '../../pages/home/Home';
import Reporting from '../../pages/reporting/Reporting';

import Sidebar from '../Sidebar/Sidebar';

import { logout } from '../../store/auth';
import { toggleSidebar, sidebarOpenSelector } from '../../store/ui';

import style from './Layout.module.scss';

const { Content, Footer, Header } = LayoutComponent;

const Layout = ({ logout, sidebarOpen, toggleSidebar }) => {
  const handleMenuClick = ({ key }) => {
    if (key === 'logout') {
      logout();
    }
  };

  const handleToggleClick = () => toggleSidebar();

  return (
    <LayoutComponent className={style.outerLayout}>
      <Sidebar />
      <LayoutComponent className={style.innerLayout}>
        <Header className={style.header} theme="light">
          <Icon
            type={sidebarOpen ? 'menu-fold' : 'menu-unfold'}
            onClick={handleToggleClick}
          />
          <Dropdown
            overlay={
              <Menu onClick={handleMenuClick}>
                <Menu.Item key="logout">
                  <Icon type="logout" />
                  <span>Logout</span>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Icon className={style.menuIcon} type="ellipsis" />
          </Dropdown>
        </Header>
        <Content>
          <Switch>
            <Route path="/home" exact component={Home} />
            <Route path="/reporting" component={Reporting} />
            <Route path="/" render={() => <Redirect to="/home" />} />
          </Switch>
        </Content>
        <Footer className={style.footer}>
          <b>WeAre8</b>
        </Footer>
      </LayoutComponent>
    </LayoutComponent>
  );
};

const mapStateToProps = (state) => ({
  sidebarOpen: sidebarOpenSelector(state),
});
const dispatchProps = { logout, toggleSidebar };

export default connect(mapStateToProps, dispatchProps)(Layout);
