import React, { useState } from 'react';

import { connect } from 'react-redux';
import { Button, Input, Form } from 'antd';

import { fetchingSelector, loginAsync } from '../../store/auth';

import style from './Login.module.scss';

const Login = ({ fetching, login }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (event) => setEmail(event?.target?.value);

  const handlePasswordChange = (event) => setPassword(event?.target?.value);

  const handleSubmit = (event) => {
    event.preventDefault();

    login({ email, password });
  };

  return (
    <div className={style.loginPage}>
      <div className={style.widgetPadding}>
        <h5 className={style.heading}>
          WeAreReporting<span>.</span>
        </h5>

        <div className={style.loginFormCont}>
          <p className={style.reportingDashboardText}>
            WeAre8 Reporting Dashboard
          </p>

          <Form onSubmit={handleSubmit}>
            <Input
              className={style.input}
              name="email"
              onChange={handleEmailChange}
              placeholder="Email"
              type="email"
              value={email}
            />

            <Input.Password
              className={style.input}
              name="password"
              onChange={handlePasswordChange}
              placeholder="Password"
              type="password"
              value={password}
            />

            <div className={style.buttonContainer}>
              <Button disabled={true}>Create an Account</Button>
              <Button
                className={style.loginButton}
                htmlType="submit"
                icon="login"
                loading={fetching}
              >
                Login
              </Button>
            </div>
          </Form>

          <div className={style.accountTroubleLink}>
            <Button type="link">Trouble with account?</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ fetching: fetchingSelector(state) });
const dispatchProps = { login: loginAsync.request };

export default connect(mapStateToProps, dispatchProps)(Login);
