import React from 'react';

import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Icon, Typography } from 'antd';
import { Card } from 'eight.js.ui-common';

import style from './Home.module.scss';

const Home = ({ push }) => {
  return (
    <div>
      <Typography.Title className={style.title} level={2}>
        Welcome
      </Typography.Title>

      <div className={style.container}>
        <Card
          bodyStyle={{
            textAlign: 'center',
          }}
          className={style.card}
          style={{ marginLeft: 0, marginRight: 0 }}
          onClick={() => push('/reporting/users')}
        >
          <Icon className={style.icon} type="area-chart" />
          <Typography.Title level={3}>Reporting</Typography.Title>
          <p>View reports and platform insights.</p>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});
const dispatchProps = { push };

export default connect(mapStateToProps, dispatchProps)(Home);
