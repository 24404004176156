import React, { useState } from 'react';

import { Button, List, Progress } from 'antd';

import { numberWithCommas } from '../../util';

import style from './GeographicList.module.scss';

const ListHeader = ({ title }) => (
  <div className={style.listHeader}>
    <span>{title}</span>
    <span>Users</span>
  </div>
);

const ListItem = ({ item }) => {
  const name = item?.metadata?.countryFullName || item?.name;
  const percentage = item?.metadata?.percentage;
  const percent = percentage && Math.round(Number(percentage));

  return (
    <List.Item className={style.listItem}>
      <div>{name}</div>
      <Progress
        format={() => numberWithCommas(item.x)}
        percent={percent}
        status="normal"
        strokeColor="#63a48c"
        strokeLinecap="square"
      />
    </List.Item>
  );
};

const ListFooter = ({ buttonText, onClick }) => {
  return (
    <div className={style.listFooter}>
      <Button onClick={onClick} type="link">
        {buttonText}
      </Button>
    </div>
  );
};

const GeographicList = ({ dataSource, fetching }) => {
  const [showAll, setShowAll] = useState(false);

  const { data, name } = dataSource;

  const sortedData = [...data].sort((a, b) => b.x - a.x);

  let titleSingular;
  let titlePlural;
  if (name === 'countryCode') {
    titleSingular = 'Country';
    titlePlural = 'Countries';
  } else {
    titleSingular = 'City';
    titlePlural = 'Cities';
  }

  const toggleShowAll = () => setShowAll((all) => !all);

  return (
    <List
      dataSource={showAll ? sortedData : sortedData?.slice(0, 7)}
      loading={fetching}
      header={<ListHeader title={titleSingular} />}
      footer={
        sortedData?.length > 7 && (
          <ListFooter
            buttonText={showAll ? 'Back' : `View ${titlePlural}`}
            onClick={toggleShowAll}
          />
        )
      }
      renderItem={(item) => <ListItem item={item} />}
      style={{ flex: 1 }}
    />
  );
};

export default GeographicList;
