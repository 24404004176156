import config from '../../config';

export class AuthService {
  constructor(dataService, tokenService) {
    this.dataService = dataService;
    this.tokenService = tokenService;
  }

  login = async ({ email, password }) => {
    const response = await this.dataService.fetch({
      url: `${config.authAPI}/connect/token`,
      method: 'POST',
      bodyType: 'urlencoded',
      body: {
        username: email,
        password,
        grant_type: 'password',
        client_id: 'reporting.ui.client',
        client_secret: config.auth.clientSecret,
        scopes: 'offline_access analytics.api admin.api',
      },
    });

    if (response.status !== 200 && response.status !== 201) {
      throw new Error('Invalid Credentials');
    }

    this.tokenService.storeTokens({
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
    });

    return response.data;
  };

  logout = () => {
    localStorage.removeItem(config.auth.accessTokenStorageKey);
    localStorage.removeItem(config.auth.refreshTokenStorageKey);
    document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  };

  refresh = async () => {
    const { refreshToken } = this.tokenService.retrieveTokens();

    const response = await this.dataService.fetch({
      authorization: false,
      url: `${config.authAPI}/connect/token`,
      method: 'POST',
      bodyType: 'urlencoded',
      body: {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        client_id: 'reporting.ui.client',
        client_secret: config.auth.clientSecret,
      },
    });

    if (response.status !== 200 && response.status !== 201) {
      throw new Error('Invalid Refresh Token');
    }

    this.tokenService.storeTokens({
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
    });

    return response.data;
  };
}
