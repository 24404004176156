import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { adminReducer } from './admin';
import { analyticsReducer } from './analytics';
import { authReducer } from './auth';
import { uiReducer } from './ui';

export const createRootReducer = (history) =>
  combineReducers({
    admin: adminReducer,
    analytics: analyticsReducer,
    auth: authReducer,
    ui: uiReducer,

    router: connectRouter(history),
  });
