import { loginAsync, logout, refreshAsync } from './actions';

import { initialAsyncState, createReducer } from 'eight.js.ui-common';

const initialState = {
  ...initialAsyncState,
  accessToken: null,
  refreshToken: null,
  refreshing: false,
};

export const authReducer = createReducer('@@auth', initialState, {
  [loginAsync.success]: ({ state, action }) => {
    state.accessToken = action.payload.access_token;
    state.refreshToken = action.payload.refresh_token;
  },

  [logout]: ({ state }) => {
    state.accessToken = null;
    state.refreshToken = null;
  },

  [refreshAsync.request]: ({ state }) => {
    state.refreshing = true;
  },

  [refreshAsync.success]: ({ state, action }) => {
    state.accessToken = action.payload.access_token;
    state.refreshToken = action.payload.refresh_token;
    state.refreshing = false;
  },

  [refreshAsync.failure]: ({ state }) => {
    state.refreshing = false;
  },
});
