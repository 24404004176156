import React from 'react';

import { DatePicker, Select } from 'antd';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { push } from 'connected-react-router';
import classnames from 'classnames';
import moment from 'moment';
import querystring from 'query-string';
import _ from 'lodash';

import Spinner from '../../../../components/Spinner/Spinner';

import {
  clearCities,
  clearCountries,
  clearRegions,
  fetchCitiesAsync,
  fetchCountriesAsync,
  fetchRegionsAsync,
  citiesSelector,
  countriesSelector,
  regionsSelector,
  timezonesSelector,
} from '../../../../store/admin';

import style from './DemographicsFilters.module.scss';

const ageOptions = new Array(99).fill(null).map((_, i) => (
  <Select.Option key={i} value={i + 1}>
    {i + 1}
  </Select.Option>
));

const dateFormat = 'YYYY-MM-DD';

const dateRanges = {
  Today: [moment(), moment()],
  Yesterday: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
  'This Week': [moment().startOf('week'), moment()],
  'Last Week': [
    moment().subtract(1, 'week').startOf('week'),
    moment().subtract(1, 'week').endOf('week'),
  ],
  'This Month': [moment().startOf('month'), moment()],
  'Last Month': [
    moment().subtract(1, 'month').startOf('month'),
    moment().subtract(1, 'month').endOf('month'),
  ],
  'Last 7 Days': [moment().subtract(7, 'days'), moment()],
  'Last 30 Days': [moment().subtract(30, 'days'), moment()],
  'Last 90 Days': [moment().subtract(90, 'days'), moment()],
  'Last 12 Months': [moment().subtract(12, 'months'), moment()],
  'This Year': [moment().startOf('year'), moment()],
};

const getLocationOptions = (data) =>
  data?.map((item) => (
    <Select.Option key={item.value} value={item.value}>
      {item.display}
    </Select.Option>
  ));

const getNotFoundContent = (fetching) =>
  fetching ? (
    <div className={style.searchSelectSpinner}>
      <Spinner />
    </div>
  ) : null;

// const getTimezoneOptions = (timezones) =>
//   timezones.map((timezone, i) => (
//     <Select.OptGroup key={i} label={timezone[0].offsetString}>
//       {timezone.map(({ display, value }) => (
//         <Select.Option key={value} value={value}>
//           {display}
//         </Select.Option>
//       ))}
//     </Select.OptGroup>
//   ));

const LocationSelect = ({
  children,
  disabled,
  fetching,
  onChange,
  onSearch,
  value,
}) => (
  <Select
    allowClear={true}
    className={style.searchSelect}
    defaultActiveFirstOption={false}
    disabled={disabled}
    filterOption={false}
    loading={fetching}
    notFoundContent={getNotFoundContent(fetching)}
    onChange={onChange}
    onSearch={onSearch}
    placeholder="search..."
    showArrow={false}
    showSearch
    size="small"
    value={value}
  >
    {children}
  </Select>
);

// const TimezoneSelect = ({ children, onChange, value }) => (
//   <Select
//     className={style.searchSelect}
//     filterOption={(input, { key }) =>
//       key.toLowerCase().includes(input.toLowerCase())
//     }
//     optionFilterProp="children"
//     onChange={onChange}
//     placeholder="select a timezone"
//     showSearch
//     size="small"
//     value={value}
//   >
//     {children}
//   </Select>
// );

const DemographicsFilters = ({
  cities,
  clearCities,
  clearCountries,
  clearRegions,
  countries,
  fetchCities,
  fetchCountries,
  fetchRegions,
  push,
  regions,
  // timezones,
}) => {
  const { pathname, search } = useLocation();

  const filters = querystring.parse(search);

  const handleChange = (key) => (value) => {
    const next = {
      ...filters,
      [key]: value,
    };

    push(`${pathname}?${querystring.stringify(next)}`);
  };

  const handleDateChange = (_, [fromDate, toDate]) => {
    const next = {
      ...filters,
      fromDate,
      toDate,
    };

    push(`${pathname}?${querystring.stringify(next)}`);
  };

  const handleLocationChange = (key) => (value) => {
    let next = {
      ...filters,
      [key]: value,
    };

    if (key === 'country') {
      delete next.region;
      delete next.city;

      if (!value) {
        clearCountries();
        clearCities();
        clearRegions();
      }
    }

    if (key === 'region') {
      delete next.city;

      if (!value) {
        clearCities();
        clearRegions();
      }
    }

    if (key === 'city' && !value) {
      clearCities();
    }

    push(`${pathname}?${querystring.stringify(next)}`);
  };

  const handleSearch = (key) =>
    _.debounce((term) => {
      if (term) {
        const { country, region } = filters;

        if (key === 'city') {
          fetchCities({ city: term, country, region });
        }

        if (key === 'country') {
          fetchCountries({ country: term });
        }

        if (key === 'region') {
          fetchRegions({ country, region: term });
        }
      }
    }, 300);

  const cityValue = cities.results.find((city) => city.value === filters.city);
  const countryValue = countries.results.find(
    (country) => country.value === filters.country
  );
  const regionValue = regions.results.find(
    (region) => region.value === filters.region
  );

  return (
    <>
      <div className={style.container}>
        <div className={style.innerContainer}>
          <div className={style.group}>
            <label className={style.label}>Type:</label>

            <Select
              className={style.select}
              onChange={handleChange('users')}
              size="small"
              value={filters.users}
            >
              <Select.Option value="all">All</Select.Option>
              <Select.Option value="new">New</Select.Option>
              <Select.Option value="returning">Returning</Select.Option>
            </Select>
          </div>

          <div className={style.group}>
            <label className={style.label}>Platform:</label>

            <Select
              className={style.select}
              onChange={handleChange('platform')}
              size="small"
              value={filters.platform}
            >
              <Select.Option value="all">All</Select.Option>
              <Select.Option value="i_phone">iOS</Select.Option>
              <Select.Option value="android_phone">Android</Select.Option>
            </Select>
          </div>

          <div className={style.group}>
            <label className={style.label}>Gender:</label>

            <Select
              className={style.select}
              onChange={handleChange('gender')}
              size="small"
              value={filters.gender}
            >
              <Select.Option value="all">All</Select.Option>
              <Select.Option value="male">Male</Select.Option>
              <Select.Option value="female">Female</Select.Option>
            </Select>
          </div>

          <div className={style.group}>
            <label className={style.label}>Age:</label>

            <Select
              allowClear={true}
              className={classnames(style.select, style.ageSelectFirst)}
              onChange={handleChange('fromAge')}
              placeholder="From"
              size="small"
              value={filters.fromAge}
            >
              {ageOptions}
            </Select>

            <Select
              allowClear={true}
              className={style.select}
              onChange={handleChange('toAge')}
              placeholder="To"
              size="small"
              value={filters.toAge}
            >
              {ageOptions}
            </Select>
          </div>
        </div>

        <div className={style.innerContainer}>
          <DatePicker.RangePicker
            allowClear={false}
            format={dateFormat}
            onChange={handleDateChange}
            ranges={dateRanges}
            separator="-"
            size="small"
            value={[moment(filters.fromDate), moment(filters.toDate)]}
          />
        </div>
      </div>

      <div className={style.container} style={{ justifyContent: 'flex-start' }}>
        <div className={style.group}>
          <label className={style.label}>Country:</label>
          <LocationSelect
            fetching={countries.fetching}
            onSearch={handleSearch('country')}
            onChange={handleLocationChange('country')}
            value={countryValue?.display}
          >
            {getLocationOptions(countries.results)}
          </LocationSelect>
        </div>

        <div className={style.group}>
          <label className={style.label}>Region:</label>
          <LocationSelect
            disabled={!filters.country}
            fetching={regions.fetching}
            onSearch={handleSearch('region')}
            onChange={handleLocationChange('region')}
            value={regionValue?.display}
          >
            {getLocationOptions(regions.results)}
          </LocationSelect>
        </div>

        <div className={style.group}>
          <label className={style.label}>City:</label>
          <LocationSelect
            disabled={!filters.country || !filters.region}
            fetching={cities.fetching}
            onSearch={handleSearch('city')}
            onChange={handleLocationChange('city')}
            value={cityValue?.display}
          >
            {getLocationOptions(cities.results)}
          </LocationSelect>
        </div>

        {/* <div className={style.group}>
          <label className={style.label}>Timezone:</label>
          <TimezoneSelect>{getTimezoneOptions(timezones)}</TimezoneSelect>
        </div> */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  cities: citiesSelector(state),
  countries: countriesSelector(state),
  regions: regionsSelector(state),
  timezones: timezonesSelector(state),
});
const dispatchProps = {
  clearCities,
  clearCountries,
  clearRegions,
  fetchCities: fetchCitiesAsync.request,
  fetchCountries: fetchCountriesAsync.request,
  push,
  fetchRegions: fetchRegionsAsync.request,
};

export default connect(mapStateToProps, dispatchProps)(DemographicsFilters);
