import { createSelector } from 'reselect';

const adminStateSelector = (state) => state.admin;

export const citiesSelector = createSelector(
  adminStateSelector,
  (state) => state.cities
);

export const countriesSelector = createSelector(
  adminStateSelector,
  (state) => state.countries
);

export const regionsSelector = createSelector(
  adminStateSelector,
  (state) => state.regions
);

export const timezonesSelector = createSelector(
  adminStateSelector,
  (state) => state.timezones
);
