import React from 'react';

import { Icon, Layout, Menu } from 'antd';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { push } from 'connected-react-router';
import classnames from 'classnames';

import { sidebarOpenSelector } from '../../store/ui';

import style from './Sidebar.module.scss';

const { Sider } = Layout;

const Sidebar = ({ push, sidebarOpen }) => {
  const { pathname } = useLocation();
  const [, route] = pathname.split('/');
  const selected = `/${route}`;
  const selectedColor = '#05002a';

  return (
    <Sider className={style.sider} collapsed={!sidebarOpen}>
      {sidebarOpen ? (
        <div className={classnames(style.container, style.containerColumn)}>
          <div className={style.brand}>WeAre8</div>
          <div className={style.title}>Reporting Dashboard</div>
        </div>
      ) : (
        <div className={style.container}>
          <img
            alt="WeAre8 Logo"
            className={style.logo}
            src={`${process.env.PUBLIC_URL}/logo.png`}
          />
        </div>
      )}
      <Menu
        className={style.sider}
        onClick={({ key }) => push(key)}
        selectedKeys={[selected]}
        theme="dark"
      >
        <Menu.Item
          key="/home"
          style={{ backgroundColor: selected === '/home' && selectedColor }}
        >
          <Icon type="home" />
          <span>Home</span>
        </Menu.Item>
        <Menu.Item
          key="/reporting"
          style={{
            backgroundColor: selected === '/reporting' && selectedColor,
          }}
        >
          <Icon type="area-chart" />
          <span>Reporting</span>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

const mapStateToProps = (state) => ({
  sidebarOpen: sidebarOpenSelector(state),
});
const dispatchProps = { push };

export default connect(mapStateToProps, dispatchProps)(Sidebar);
