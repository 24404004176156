import config from '../../config';

export class AnalyticsService {
  constructor(dataService) {
    this.dataService = dataService;
  }

  getAverageTimeSpentInApp = async () => {
    const response = await this.dataService.fetch({
      url: `${config.analyticsAPI}/influencers/reporting/average-time`,
      method: 'GET',
    });

    return response.data;
  };

  getDemographics = async ({
    city,
    country,
    fromAge,
    fromDate,
    gender,
    platform,
    region,
    toAge,
    toDate,
    users,
  } = {}) => {
    const response = await this.dataService.fetch({
      url: `${config.analyticsAPI}/influencers/reporting/demographics`,
      method: 'POST',
      body: {
        fromAge,
        fromDate,
        gender,
        City: city,
        countryCode: country,
        regionCode: region,
        platform,
        toAge,
        toDate,
        users,
      },
    });

    return response.data.data;
  };

  getHeatMap = async ({
    city,
    country,
    fromAge,
    fromDate,
    gender,
    platform,
    region,
    toAge,
    toDate,
    users,
  } = {}) => {
    const response = await this.dataService.fetch({
      url: `${config.analyticsAPI}/influencers/reporting/heatmap`,
      method: 'POST',
      body: {
        fromAge,
        fromDate,
        gender,
        city: city,
        countryCode: country,
        regionCode: region,
        platform,
        toAge,
        toDate,
        users,
      },
    });

    return response.data.data;
  };

  getInfluencerTotals = async () => {
    const response = await this.dataService.fetch({
      url: `${config.analyticsAPI}/influencers/reporting/totals`,
      method: 'GET',
    });

    return response.data;
  };

  getNewInfluencersPast30Days = async () => {
    const response = await this.dataService.fetch({
      url: `${config.analyticsAPI}/influencers/reporting/new-daily-past-thirty-day`,
      method: 'GET',
    });

    return response.data;
  };

  getNewInfluencersPast12Months = async () => {
    const response = await this.dataService.fetch({
      url: `${config.analyticsAPI}/influencers/reporting/new-monthly-past-twelve-months`,
      method: 'GET',
    });

    return response.data;
  };
}
