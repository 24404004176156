import { createSelector } from 'reselect';

import { container } from '../di';

const tokenService = container.get('TokenService');

const authStateSelector = (state) => state.auth;

export const isAuthenticatedSelector = createSelector(
  authStateSelector,
  ({ accessToken }) => {
    if (!accessToken) {
      return false;
    }

    const { exp } = tokenService.decodeToken(accessToken);

    return new Date().getTime() / 1000 <= exp;
  }
);

export const fetchingSelector = createSelector(
  authStateSelector,
  (state) => state.fetching
);

export const refreshingSelector = createSelector(
  authStateSelector,
  (state) => state.refreshing
);
