import _ from 'lodash';

import { numberWithCommas } from '../../util';

export const getAgeGroupBarChartOptions = ({ data, name }) => ({
  xAxis: {
    categories: data.map(({ y }) => y),
    type: 'category',
  },
  yAxis: {
    title: {
      text: 'Users',
    },
  },
  title: {
    text: 'Age Group',
    style: {
      fontSize: 14,
    },
  },
  series: [
    {
      name,
      data: data.map(({ x, y, metadata }) => ({
        y: x,
        metadata: { ...metadata, y },
      })),
    },
  ],
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
        formatter() {
          return numberWithCommas(this.point.y);
        },
      },
    },
  },
  tooltip: {
    borderColor: '#EEEEEE',
    borderRadius: 20,
    formatter() {
      return `
        <div>
          <b>${this.point.metadata.y}<b>
          <br/>
          <span>${numberWithCommas(this.point.y)} (${Math.round(
        Number(this.point.metadata.percentage)
      )}%)</span>
        </div>
      `;
    },
  },
});

export const getMapChartOptions = ({ data }) => ({
  title: {
    text: 'Countries',
    style: {
      fontSize: 14,
    },
  },
  series: [
    {
      data: data.map(({ x, y }) => [y.toLowerCase(), x]),
    },
  ],
  tooltip: {
    useHTML: true,
    borderColor: '#EEEEEE',
    borderRadius: 20,
    formatter() {
      const abbr = this.point['hc-key'];
      const country = data.find(({ y }) => y.toLowerCase() === abbr);

      return `
        <div style="display: flex; flex-direction: column; height: 35px">
          <img src="${country.metadata.countryFlagUrl}" style="width: 20px" />
          <span><b>${country.metadata.countryFullName}</b></span>
        </div>
        <div>
        ${numberWithCommas(country.x)} (${Math.round(
        Number(country.metadata.percentage)
      )}%)
        </div>
      `;
    },
  },
});

export const getPieChartOptions = ({ data, name }) => ({
  title: {
    text: _.upperFirst(name),
    style: {
      fontSize: 14,
    },
  },
  series: [
    {
      name,
      data: data.map(({ name, x }) => ({
        name,
        y: x,
      })),
    },
  ],
  tooltip: {
    borderColor: '#EEEEEE',
    borderRadius: 20,
    formatter() {
      const item = data.find(({ name }) => name === this.point.name);
      const percentage = item
        ? `(${Math.round(Number(item.metadata.percentage))}%)`
        : '';

      return `
        <b>${this.point.name}<b>
        <br/>
        <span>${numberWithCommas(this.point.y)} ${percentage}</span>
      `;
    },
  },
  legend: false,
});

export const getCardBodyStyle = (show) => {
  let style = {
    height: '100%',
    width: '100%',
  };

  if (!show) {
    style.display = 'flex';
    style.alignItems = 'center';
    style.justifyContent = 'center';
  }

  return style;
};
