import moment from 'moment';

export const getDefaultSearch = (pathname) => {
  let defaultSearch = {
    fromDate: moment().startOf('month').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  };

  if (pathname.includes('demographics')) {
    defaultSearch.gender = 'all';
    defaultSearch.platform = 'all';
    defaultSearch.users = 'all';
  }

  return defaultSearch;
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
