import moment from 'moment';
import { createReducer, initialAsyncState } from 'eight.js.ui-common';

import {
  fetchAverageTimeSpentInAppAsync,
  fetchHeatMapAsync,
  fetchInfluencersTotalsAsync,
  fetchNewInfluencersPast30DaysAsync,
  fetchNewInfluencersPast12MonthsAsync,
  fetchDemographicsAsync,
} from './actions';

const initialState = {
  ...initialAsyncState,
  demographics: {
    fetching: true,

    byCountry: {
      name: null,
      data: [],
    },
    byCity: {
      name: null,
      data: [],
    },
    byAge: {
      name: null,
      data: [],
    },
    byAgeGroup: {
      name: null,
      data: [],
    },
    byGender: {
      name: null,
      data: [],
    },
    byPlatform: {
      name: null,
      data: [],
    },
  },

  heatMap: {
    fetching: true,

    byCountry: {
      name: null,
      data: [],
    },
  },

  influencers: {
    totals: {
      fetching: true,

      total: null,
      totalQuarter: null,
      totalMonth: null,
      totalWeek: null,
      totalDay: null,
    },
    newPast30Days: {
      fetching: true,

      xAxis: null,
      series: [],
    },
    newPast12Months: {
      fetching: true,

      xAxis: null,
      series: [],
    },
    averageTimeSpentInApp: {
      fetching: true,

      xAxis: null,
      series: [],
    },
  },
};

export const analyticsReducer = createReducer('@@analytics', initialState, {
  [fetchAverageTimeSpentInAppAsync.success]: ({ state, action }) => {
    const { xAxis, series } = action.payload;

    state.influencers.averageTimeSpentInApp = {
      xAxis,
      series,
    };
  },

  [fetchDemographicsAsync.request]: ({ state }) => {
    state.demographics.fetching = true;
  },

  [fetchDemographicsAsync.success]: ({ state, action }) => {
    const {
      influencersByCountryCode,
      influencersByCityName,
      influencersByAge,
      influencerByAgeGrouped,
      influencersByGender,
      influencersByPlatform,
    } = action.payload;

    state.demographics = {
      fetching: false,

      byCountry: influencersByCountryCode[0],
      byCity: influencersByCityName[0],
      byAge: influencersByAge[0],
      byAgeGroup: influencerByAgeGrouped[0],
      byGender: influencersByGender[0],
      byPlatform: influencersByPlatform[0],
    };

    const preferIndex = state.demographics.byGender.data.findIndex(
      (item) => item.name === 'Prefernottosay'
    );
    const listedIndex = state.demographics.byGender.data.findIndex(
      (item) => item.name === 'Notlisted'
    );

    if (preferIndex > -1) {
      state.demographics.byGender.data[preferIndex].name = 'Prefer not to say';
    }
    if (listedIndex > -1) {
      state.demographics.byGender.data[listedIndex].name = 'Not listed';
    }
  },

  [fetchDemographicsAsync.failure]: ({ state }) => {
    state.demographics.fetching = false;
  },

  [fetchHeatMapAsync.request]: ({ state }) => {
    state.heatMap.fetching = true;
  },

  [fetchHeatMapAsync.success]: ({ state, action }) => {
    const { heatMapByInfluencerCountryCode } = action.payload;

    state.heatMap = {
      fetching: false,

      byCountry: heatMapByInfluencerCountryCode[0],
    };
  },

  [fetchHeatMapAsync.failure]: ({ state }) => {
    state.heatMap.fetching = false;
  },

  [fetchInfluencersTotalsAsync.request]: ({ state }) => {
    state.influencers.totals = initialState.influencers.totals;
  },

  [fetchInfluencersTotalsAsync.success]: ({ state, action }) => {
    const {
      total_users,
      total_quarter,
      total_this_month,
      total_this_week,
      total_today,
    } = action.payload;

    state.influencers.totals = {
      fetching: false,

      total: total_users,
      totalQuarter: total_quarter,
      totalMonth: total_this_month,
      totalWeek: total_this_week,
      totalDay: total_today,
    };
  },

  [fetchInfluencersTotalsAsync.failure]: ({ state }) => {
    state.influencers.fetching = false;
    state.influencers.totals = initialState.influencers.totals;
  },

  [fetchNewInfluencersPast30DaysAsync.request]: ({ state }) => {
    state.influencers.newPast30Days.fetching = true;
  },

  [fetchNewInfluencersPast30DaysAsync.success]: ({ state, action }) => {
    const { xAxis, series } = action.payload;

    const formattedSeries = series.map((s) => ({
      ...s,
      data: s.data.map(({ x, y }) => [
        moment(x).add(1, 'day').format('MM/DD'),
        y,
      ]),
    }));

    state.influencers.newPast30Days = {
      fetching: false,

      xAxis,
      series: formattedSeries,
    };
  },

  [fetchNewInfluencersPast30DaysAsync.failure]: ({ state }) => {
    state.influencers.newPast30Days.fetching = false;
  },

  [fetchNewInfluencersPast12MonthsAsync.request]: ({ state }) => {
    state.influencers.newPast12Months.fetching = true;
  },

  [fetchNewInfluencersPast12MonthsAsync.success]: ({ state, action }) => {
    const { xAxis, series } = action.payload;

    const formattedSeries = series.map((s) => ({
      ...s,
      data: s.data.map(({ x, y }) => [
        moment(x).add(1, 'day').format('MMMM'),
        y,
      ]),
    }));

    state.influencers.newPast12Months = {
      fetching: false,

      xAxis,
      series: formattedSeries,
    };
  },

  [fetchNewInfluencersPast12MonthsAsync.failure]: ({ state }) => {
    state.influencers.newPast12Months.fetching = false;
  },
});
